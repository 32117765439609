import "./App.css";
import { Suspense, lazy } from "react";
import Section1 from "./components/Section1/Section1";

const Appbar = lazy(() => import("./components/Appbar/Appbar"));

const Section2 = lazy(() => import("./components/Section2/Section2"));

const Section3 = lazy(() => import("./components/Section3/Section3"));

const Section4 = lazy(() => import("./components/Section4/Section4"));

const Section5 = lazy(() => import("./components/Section5/Section5"));

const Section6 = lazy(() => import("./components/Section6/Section6"));
const Section8 = lazy(() => import("./components/Section8/Section8"));
const Section9 = lazy(() => import("./components/Section9/Section9"));
function App() {
  return (
    <div className="App">
      {/* <Appbar /> */}
      <Section1 />
      <Suspense fallback={<div />}>
        <Section2 />
      </Suspense>
      <Suspense fallback={<div />}>
        <Section3 />
      </Suspense>
      <Suspense fallback={<div />}>
        <Section4 />
      </Suspense>
      {/* <Section5 /> */}
      <Suspense fallback={<div />}>
        <Section6 />
      </Suspense>
      <Suspense fallback={<div />}>
        <Section8 />
      </Suspense>
      <Suspense fallback={<div />}>
        <Section9 />
      </Suspense>
      {/* <Section10 /> */}
    </div>
  );
}

export default App;
