import React from "react";
import "./Appbar.css";
import list from "./menu.png";
import close from "./close (1).png";
import logo from "./White-Logo 1.png";

function Appbar() {
  const [click, setClick] = React.useState(false);

  const handleClick = () => setClick(!click);
  const Close = () => setClick(false);
  return (
    <>
      <div>
        <div
          className={click ? "main-container" : ""}
          onClick={() => Close()}
        />
        <nav className="navbar" onClick={(e) => e.stopPropagation()}>
          <div className="nav-container">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="nav__width">
                <a href="/" className="nav-logo">
                  <img className="bar__logo" src={logo} alt="" />
                </a>
              </div>
            </div>
            <div>
              <ul className={click ? "nav-menu active" : "nav-menu"}>
                <li className="nav-item">
                  <a
                    href="#About"
                    activeClassName="active"
                    className="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    ABOUT
                  </a>
                </li>
                <li className="nav-item nav-item-hr">
                  <a
                    href=""
                    activeClassName="active"
                    className="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    <hr></hr>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#NFTs"
                    activeClassName="active"
                    className="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    NFTs
                  </a>
                </li>
                <li className="nav-item nav-item-hr">
                  <a
                    href="/"
                    activeClassName="active"
                    className="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    <hr></hr>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#Roadmap"
                    activeClassName="active"
                    className="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    ROADMAP
                  </a>
                </li>
                <li className="nav-item nav-item-hr">
                  <a
                    href=""
                    activeClassName="active"
                    className="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    <hr></hr>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#Investors"
                    activeClassName="active"
                    className="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    INVESTORS
                  </a>
                </li>
                <li className="nav-item nav-item-hr">
                  <a
                    href="/"
                    activeClassName="active"
                    className="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    <hr></hr>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#Faq"
                    activeClassName="active"
                    className="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    FAQ
                  </a>
                </li>
                <li className="nav-item nav-item-hr">
                  <a
                    href="/"
                    activeClassName="active"
                    className="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    <hr></hr>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="/"
                    activeClassName="active"
                    className="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    <img
                      className="connet-wallet"
                      src="./assets/Group 14867.png"
                      alt=""
                    />
                  </a>
                </li>
              </ul>
            </div>
            <div onClick={handleClick}>
              <img
                src="./assets/Group 14900.png"
                style={{ color: "white" }}
                className={click ? "nav-icon2" : "nav-icon"}
              ></img>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}

export default Appbar;
