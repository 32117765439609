import React from "react";
import Appbar from "../Appbar/Appbar";
import Countdown from "./Countdown";
import "./Section1.css";
function Section1() {
  return (
    <div className="section1">
      <div>
        <Appbar />
      </div>
      <div className="section1__body">
        <img className="p1" src="./assets/p1.png" alt="" />
        <img className="section1__video" src="./assets/Frame.png" alt="" />
      </div>
      <img className="p2" src="./assets/p2.png" alt="" />

      <div className="section2__title">
        AcknoLedger Genesis NFT
        <br></br>
        <div className="arrow__title">
          <div>
            <img className="arrow" src="./assets/arrow.svg" alt="" />
          </div>
          <div className="arrow__text">Launch Timer</div>
          <div>
            <img
              className="arrow__right arrow"
              src="./assets/arrow.svg"
              alt=""
            />
          </div>
        </div>
      </div>
      <Countdown
        timeTillDate="02 09 2022, 19:30 pm"
        timeFormat="MM DD YYYY, h:mm a"
      />
    </div>
  );
}

export default Section1;
